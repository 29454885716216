.welcome-bg {
  margin-bottom: 30px;
  max-width: 100%;
  height: 350px;
  z-index: 0;
}

.welcome-image {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
}
.welcome-item {
  position: relative;
  margin-top: 30px;
  margin-bottom: 200px;
}
.welcome-title {
  max-width: 724px;
  padding-top: 118px;
  margin-bottom: 0;
  font-family: "SFUIText-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #003285;
  z-index: 3;
}
.welcome-subtitle {
  max-width: 1013px;
  width: 100%;
  font-family: "SFUIText-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 70px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #003285;
  z-index: 3;
}

.welcome-text {
  max-width: 1013px;
  width: 100%;
  font-family: "SFUIText-Light";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #003285;
  z-index: 100;
}
.welcome-subtexts {
  max-width: 750px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.welcome-button {
  max-width: 140px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.welcome-btn {
  margin-top: 5px;
  margin-bottom: 0;
  font-family: "SFUIText-Semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #307ad6;
}
.welcome-link {
  text-decoration: none;
}
.wel-description {
  margin-top: -30px;
  max-width: 330px;
  width: 100%;
  font-family: "SFUIText-Light";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #aaaaaa;
  text-transform: none;
}
.wel-text {
  max-width: 855px;
  font-family: "SFUIText-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 45px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #003285;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .welcome-item {
    margin-bottom: 50px;
  }
  .welcome-title {
    max-width: 500px;
    font-size: 60px;
  }
  .welcome-subtitle {
    font-size: 60px;
  }
  .wel-text {
    max-width: 700px;
    font-size: 32px;
  }
  .welcome-subtexts {
    max-width: 500px;
  }
  .wel-description {
    margin-top: 0;
  }
  .welcome-image {
    width: 350px;
    height: 652px;
  }
}

@media (max-width: 992px) {
  .welcome-item {
    margin-top: 0;
    margin-bottom: 70px;
  }
  .welcome-title {
    max-width: 400px;
    font-size: 45px;
    line-height: 50px;
  }
  .welcome-subtitle {
    font-size: 45px;
    line-height: 45px;
  }
  .welcome-subtexts {
    max-width: 400px;
    width: 100%;
    gap: 30px;
  }
  .welcome-subtext {
    max-width: 500px;
    width: 100%;
    margin-top: 20px;
  }
  .wel-text {
    font-family: "SFUIText-Light";
    max-width: 440px;
    font-size: 30px;
    line-height: 40px;
  }
  .wel-description {
    font-size: 16px;
    max-width: 250px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .welcome-button {
    max-width: 120px;
  }
  .welcome-btn {
    font-size: 16px;
  }
  .welcome-image {
    width: 270px;
    height: 502px;
    top: 20px;
  }
  .welcome-svg {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 768px) {
  .welcome-title {
    font-size: 38px;
    line-height: 35px;
    max-width: 200px;
  }
  .welcome-subtitle {
    font-size: 38px;
    line-height: 35px;
  }
  .wel-text {
    font-size: 23px;
    line-height: 30px;
  }
}
@media (max-width: 768px) {
  .welcome-image {
    top: 450px;
    right: 30%;
    left: 30%;
  }
  .welcome-item {
    margin-bottom: 600px;
  }
  .welcome-title {
    padding-top: 50px;
    max-width: 500px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .wel-text {
    max-width: 600px;
    text-align: center;
    margin-top: 20px;
    line-height: 25px;
  }
  .welcome-button {
    order: 2;
  }
  .welcome-subtexts {
    margin: auto;
    max-width: 300px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .wel-description {
    text-align: center;
    max-width: 300px;
  }
}
@media (max-device-width: 480px) {
  .welcome-subtitle,
  .welcome-title {
    text-align: center;
    font-size: 25px;
    line-height: 25px;
  }
  .wel-text {
    font-size: 18px;
  }
  .wel-description {
    font-size: 15px;
  }
  .welcome-image {
    top: 120%;
    left: 18%;
    right: 30%;
  }
  .welcome-item {
    height: 380px;
  }
  .welcome-btn {
    margin-top: 8px;
  }
}
@media (max-device-width: 380px) {
  .welcome-image {
    width: 200px;
    height: 350px;
    top: 130%;
    left: 15%;
    right: 30%;
  }
}
