@font-face {
  font-family: "SFUIText-Light";
  src: url(./Fonts/SFUIDisplay-Light.eot);
  src: url(./Fonts/SFUIDisplay-Light.ttf);
  src: url(./Fonts/SFUIDisplay-Light.woff);
  src: url(./Fonts/SFUIDisplay-Light.woff2);
}
@font-face {
  font-family: "SFUIText-Regular";
  src: url(./Fonts/SFUIDisplay-Regular.eot);
  src: url(./Fonts/SFUIDisplay-Regular.ttf);
  src: url(./Fonts/SFUIDisplay-Regular.woff);
  src: url(./Fonts/SFUIDisplay-Regular.woff2);
}
@font-face {
  font-family: "SFUIText-Medium";
  src: url(./Fonts/SFUIDisplay-Medium.eot);
  src: url(./Fonts/SFUIDisplay-Medium.ttf);
  src: url(./Fonts/SFUIDisplay-Medium.woff);
  src: url(./Fonts/SFUIDisplay-Medium.woff2);
}
@font-face {
  font-family: "SFUIText-Semibold";
  src: url(./Fonts/SFUIDisplay-Semibold.eot);
  src: url(./Fonts/SFUIDisplay-Semibold.ttf);
  src: url(./Fonts/SFUIDisplay-Semibold.woff);
  src: url(./Fonts/SFUIDisplay-Semibold.woff2);
}
@font-face {
  font-family: "SFUIText-Bold";
  src: url(./Fonts/SFUIDisplay-Bold.eot);
  src: url(./Fonts/SFUIDisplay-Bold.ttf);
  src: url(./Fonts/SFUIDisplay-Bold.woff);
  src: url(./Fonts/SFUIDisplay-Bold.woff2);
}
@font-face {
  font-family: "SFUIText-Heavy";
  src: url(./Fonts/SFUIDisplay-Heavy.eot);
  src: url(./Fonts/SFUIDisplay-Heavy.ttf);
  src: url(./Fonts/SFUIDisplay-Heavy.woff);
  src: url(./Fonts/SFUIDisplay-Heavy.woff2);
}

@font-face {
  font-family: "Helvetica-Regular";
  src: url(./Fonts/Helvetica.eot);
  src: url(./Fonts/Helvetica.ttf);
  src: url(./Fonts/Helvetica.woff);
  src: url(./Fonts/Helvetica.woff2);
}
@font-face {
  font-family: "Helvetica-Light";
  src: url(./Fonts/Helvetica-Light.eot);
  src: url(./Fonts/Helvetica-Light.ttf);
  src: url(./Fonts/Helvetica-Light.woff);
  src: url(./Fonts/Helvetica-Light.woff2);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.navbar {
  position: relative;
  background: none;
  z-index: 10;
}

.videoTag {
  margin-top: -80px;
}

@media (max-width: 480px) {
  .container {
    max-width: 450px;
    width: 100%;
    margin: auto;
  }
}
