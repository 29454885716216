.ourpicked-bg {
  background-color: #f4f4f4;
}
.ourpicked-title {
  padding-top: 70px;
  padding-bottom: 107px;
  font-family: "SFUIText-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  color: #c3c3c3;
}
.ourpicked-items-top {
  max-width: 1168px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ourpicked-items-bottom {
  max-width: 1168px;
  width: 100%;
  margin: auto;
  margin-top: 62px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ourpicked-item-top {
  position: relative;
  max-width: 531px;
  width: 100%;
  background-color: #fff;
  min-height: 468px;
  border-radius: 10px;
}

.ourpicked-item-bottom {
  position: relative;
  max-width: 531px;
  width: 100%;
  background-color: #fff;
  min-height: 267px;
  border-radius: 10px;
}
.ourpicked-image {
  position: absolute;
  top: -50px;
  left: 33px;
}
.ourpicked-item-title {
  margin-top: 76px;
  padding-left: 42px;
  font-family: "Helvetica-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: #000000;
}
.ourpicked-item-author {
  padding-left: 42px;
  margin-top: 13px;
  margin-bottom: 20px;
  font-family: "Helvetica-Light" sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 0%;
  color: #000000;
}
.ourpicked-item-text {
  padding-left: 42px;
  padding-right: 51px;
  font-family: "Helvetica-Light" sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #000000;
}

@media (max-width: 1200px) {
  .ourpicked-item-top {
    margin: auto;
    margin-bottom: 80px;
  }
  .ourpicked-item-bottom {
    margin: auto;
    margin-bottom: 60px;
  }
  .ourpicked-items-bottom {
    margin-top: 0;
    padding-bottom: 50px;
  }
  .ourpicked-title {
    margin-bottom: 20px;
  }
  .ourpicked-image {
    left: 40%;
  }
}
@media (max-width: 992px) {
  .ourpicked-title {
    font-size: 28px;
  }
}
@media (max-device-width: 480px) {
  .ourpicked-image {
    width: 70px;
    top: -40px;
  }
  .ourpicked-title {
    font-size: 22px;
    padding-bottom: 40px;
    padding-top: 30px;
    text-align: center;
  }
  .ourpicked-items-top,
  .ourpicked-items-bottom {
    width: 90%;
  }
  .ourpicked-item-text {
    padding-bottom: 30px;
    text-align: center;
  }
  .ourpicked-item-title {
    max-width: 350px;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 30px;
    text-align: center;
    padding-left: 0;
  }
  .ourpicked-item-author {
    text-align: center;
    padding-left: 0;
    margin-bottom: 40px;
  }
}
