.contacts-item {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 123px;
  padding-bottom: 146px;
}
.contacts-image-big {
  width: 500px;
  height: 500px;
}
.contacts-item-left {
  max-width: 506px;
}
.contacts-title {
  font-family: "SFUIText-Heavy";
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 48px;
  text-transform: uppercase;
  color: #c3c3c3;
}
.contacts-subtitle {
  font-family: "SFUIText-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #494949;
  margin-bottom: 24px;
}
.contacts-text-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.contacts-description {
  margin-bottom: 0;
  font-family: "SFUIText-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #494949;
  text-align-last: left;
}
.contacts-link {
  color: #494949;
}
.contacts-link:hover {
  color: #999999;
}

@media (max-width: 1200px) {
  .contacts-image-big {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 992px) {
  .contacts-item {
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 50px;
    row-gap: 20px;
  }
  .contacts-image-big {
    width: 500px;
    height: 500px;
  }
  .contacts-item-left {
    justify-content: center;
    text-align: center;
  }
  .contacts-text-wrap {
    justify-content: center;
  }
  .contacts-icon {
    display: none;
  }
  .contacts-description {
    text-align-last: center;
  }
  .contacts-title {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
@media (max-device-width: 480px) {
  .contacts-image-big {
    width: 280px;
    height: 280px;
  }
}
